<template lang="html">
    <div class="departments">
      <div>
        <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
          <div></div>         
          <div class="d-none d-md-block">
              <button class="btn btn-sm pd-x-15 btn-white btn-uppercase" @click="openAddDepartment()">
                <i data-feather="mail" class="wd-10 mg-r-5"></i> Add Department
              </button>
              <!-- <button class="btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5">
                <i data-feather="printer" class="wd-10 mg-r-5"></i> Download Report
              </button> -->
          </div>
        </div>      
        <div class="contents">          
          <CustomTable :tableFields="Department.table.table_fields" 
              :tableData="departments" 
              :title="Department.table.title" 
              :subtitle="Department.table.subtitle" 
              :searchFields="Department.table.searchFields"
              :rowData="Department.table.rowData">
          </CustomTable>         
        </div>
      </div>      
    </div>
</template>

<script>
export default {
  created() {
    this.Department = Models.Department;

    Event.$on("form:submit", async (payload) => {      
      this.addDepartment(payload.formData);
    });

    Event.$on("department:view", async (payload) => {  
      alert(" view department " + payload.department.department_id)          
      this.$router.push({
        name: "view_department",
        params: {id: payload.department.department_id},
      });
    });

    Event.$on("department:approve", async (payload) => {
      console.log(payload);
    });

    Event.$on("department:edit", async (payload) => {
      console.log(payload);
    });

    Event.$on("departmentUsers:view", async (payload) => {
      console.log("Payload: ", payload);
    });
  },
  data() {
    return {
      Department: {},
      departments: [],
    };
  },
  methods: {
    openAddDepartment() {
      this.$router.push({
        name: "add_department",
        params: {},
      });
    },
    listDepartments() {
      try {
        return new Promise(async (resolve, reject) => {
          let res = await req.callServerSecure({
            resource: "department",
            action: "list",
            data: {},
            meta_data: {},
          });

          this.departments = res.data.result.departments;
          resolve();
        });
      } catch (err) {
        reject(err);
      }
    },
    addDepartment(data) {        
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: "department",
            action: "create",
            data: {
              department_code: data.code,
              department_name: data.name,
              department_description: data.description,                                          
            },
            meta_data: {},
          });

          await this.listDepartments();          

          notification.notify(
            "success",
            "Department Entry Added",
            `The department ${data.name} was successfully added`
          );  
          
          this.$router.push({ name: "list_departments", params: {}});
        } catch (err) {
          Event.$emit("form:action-error", {
            action: this.Department.wizard.action,
          });
        }
      });
    },
  },
  async mounted() {
    await this.listDepartments();
    Event.$emit("loader:show", false);
  },
};
</script>

<style lang="less">
.intermediaries {
}
</style>
