window.axios = require('axios');


window.domain = domain;

//let endpoint = `${domain}/api/web/v1/admin`;
var BASE_PATH='/api/web/v1/admin'
var endpoint ;
var domain;
if(location=='localhost' || location =='127.0.0.1') {
   if(process.env.VUE_APP_API_URL) {
    domain=  process.env.VUE_APP_API_URL
    endpoint = domain+ BASE_PATH
   }
   else{
      domain ="https://api.nexus.ke";  
      endpoint = domain + BASE_PATH
   }
}
else{
  if(process.env.VUE_APP_API_URL) {
   domain=  process.env.VUE_APP_API_URL
   endpoint = domain+ BASE_PATH
   }else{
   // let apiEnv=location.split(".");
       let apiEnv='demo' //apiEnv[1]
        domain=`https://api.${apiEnv}.nexus.ke`;
    endpoint = domain+BASE_PATH
    
   }

}

//console.log(" ==== Endpoint === ",endpoint)

export default class Request {
  constructor() {}

  getParsedUrl(path) {
    return domain + path;
  }

  callServer(data) {
    return new Promise(async (resolve, reject) => {
      try {
        let res = await axios.post(endpoint, data);
        resolve(res);
      } catch (err) {
        console.log(err);
        notification.notify(
          'error',
          'Server Error',
          err.response.data.err_msg || err.response.data.err_code
        );
        reject(err.response);
      }
    });
  }

  callServerSecure(data) {
    return new Promise(async (resolve, reject) => {
      try {
        let token = await $session.get('token');
        if (token !== null) {
          axios.defaults.headers.common['token'] = token;
          let res = await axios.post(endpoint, data);
          resolve(res);
        } else {
          Event.$emit('token:not-found', true);
        }
        resolve(undefined);
      } catch (err) {
        console.log(err);
        notification.notify(
          'error',
          'Server Error',
          err.response.data.err_msg || err.response.data.err_code
        );
        reject(err.response);
      }
    });
  }

  callServerSecure(data) {
    return new Promise(async (resolve, reject) => {
      try {
        let token = await $session.get('token');
        if (token !== null) {
          axios.defaults.headers.common['token'] = token;
          let res = await axios.post(endpoint, data, {});
          resolve(res);
        } else {
          Event.$emit('token:not-found', true);
        }
        resolve(undefined);
      } catch (err) {
        console.log(err);
        notification.notify(
          'error',
          'Server Error',
          err.response.data.err_msg || err.response.data.err_code
        );
        reject(err.response);
      }
    });
  }

  callServerMultipart(url, data) {
    return new Promise(async (resolve, reject) => {
      try {
        let token = await $session.get('token');
        if (token !== null) {
          let res = await axios.post(domain + url, data, {
            headers: {
              token,
              'Content-Type': 'multipart/form-data',
            },
          });
          resolve(res);
        } else {
          Event.$emit('token:not-found', true);
        }
        resolve(undefined);
      } catch (err) {
        notification.notify(
          'error',
          'Server Error',
          err.response.data.err_msg || err.response.data.err_code
        );
        reject(err.response.data);
      }
    });
  }
}
