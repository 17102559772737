<template>
  <div class="edit-formField">
    <VerifyModal :showVerifyModal="showVerifyModal" />
    <div class="card mg-b-10 pd-40">
      <div
        class="card-header mg-b-40 d-sm-flex align-items-start justify-content-start bd-b-0 pd-b-0"
      >
        <div class="table-title-icon">
          <div class="f-left txt-box">
            <div class="mg-b-5 fx-h5">System Form Fields</div>
            <p class="tx-13 tx-color-03 mg-b-0">Edit Form Field</p>
          </div>
        </div>
      </div>
      <div class="card-body row">
        <!-- <slot name="form"></slot> -->
        <!-- <EditCustomForm
          :pages="EditFormField.wizard.pages"
          :action="EditFormField.wizard.action"
          :buttonText="EditFormField.wizard.actionBtn"
          :resource="formFieldToUpdate"
          :showTitle="false"
        ></EditCustomForm> -->
        <div class="col-md-4">
          <div class="form-group">
            <label for="field_code">Field Code</label>
            <input
              id="field_code"
              placeholder="Field Code"
              name="update_form_field field_code"
              type="text"
              v-model="formFieldToUpdate.field_code"
              disabled
              class="form-control"
            />
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label for="field_name">Field Name</label>
            <input
              id="field_name"
              placeholder="Field Name"
              name="update_form_field field_name"
              type="text"
              v-model="formFieldToUpdate.field_name"
              v-validate="'required|min:3|max:150'"
              class="form-control"
            />
            <small
              class="text-danger"
              v-if="errors.has('update_form_field field_name')"
              >{{ errors.first('update_form_field field_name') }}</small
            >
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label for="field_label">Field Label</label>
            <input
              id="field_label"
              placeholder="Field Label"
              name="update_form_field field_label"
              type="text"
              v-model="formFieldToUpdate.field_label"
              v-validate="'required|min:3|max:150'"
              class="form-control"
            />
            <small
              class="text-danger"
              v-if="errors.has('update_form_field field_label')"
              >{{ errors.first('update_form_field field_label') }}</small
            >
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label for="field_description">Field Hint</label>
            <textarea
              id="hint"
              placeholder="Field Hint"
              name="update_form_field hint"
              type="text"
              rows="3"
              v-model="formFieldToUpdate.hint"
              v-validate="'required|min:1|max:150'"
              class="form-control"
            ></textarea>
            <small
              class="text-danger"
              v-if="errors.has('update_form_field hint')"
              >{{ errors.first('update_form_field hint') }}</small
            >
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label for="field_description">Description</label>
            <textarea
              id="field_description"
              placeholder="Description"
              name="update_form_field field_description"
              type="text"
              rows="3"
              v-model="formFieldToUpdate.field_description"
              v-validate="'required|min:3|max:150'"
              class="form-control"
            ></textarea>
            <small
              class="text-danger"
              v-if="errors.has('update_form_field field_description')"
              >{{ errors.first('update_form_field field_description') }}</small
            >
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label for="help_text">Help Text</label>
            <textarea
              id="help_text"
              placeholder="Help Text"
              name="update_form_field help_text"
              type="text"
              rows="3"
              v-model="formFieldToUpdate.help_text"
              v-validate="'required|min:3|max:150'"
              class="form-control"
            ></textarea>
            <small
              class="text-danger"
              v-if="errors.has('update_form_field help_text')"
              >{{ errors.first('update_form_field help_text') }}</small
            >
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="type">Type</label>
            <select
              name="update_form_field type"
              id="type"
              v-model="formFieldToUpdate.type_info.type"
              v-validate="'required'"
              class="custom-select"
            >
              <option
                v-for="option in type_options"
                :value="option.val"
                :key="option.val"
              >
                {{ option.label }}
              </option>
            </select>
            <small
              class="text-danger"
              v-if="errors.has('update_form_field type')"
              >{{ errors.first('update_form_field type') }}</small
            >
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="sub_type">Sub Type</label>
            <select
              name="update_form_field sub_type"
              id="sub_type"
              v-model="formFieldToUpdate.type_info.sub_type"
              v-validate="'required'"
              class="custom-select"
            >
              <option
                v-for="option in subType_Options"
                :value="option.val"
                :key="option.val"
              >
                {{ option.label }}
              </option>
            </select>
            <small
              class="text-danger"
              v-if="errors.has('update_form_field sub_type')"
              >{{ errors.first('update_form_field sub_type') }}</small
            >
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label for="values">Field Values comma separated(,)</label>
            <textarea
              id="values"
              placeholder="Values"
              name="update_form_field values"
              type="text"
              rows="3"
              v-model="formFieldToUpdate.values"
              v-validate="'required'"
              class="form-control"
            ></textarea>
            <small
              class="text-danger"
              v-if="errors.has('update_form_field values')"
              >{{ errors.first('update_form_field values') }}</small
            >
          </div>
        </div>
        <div class="col-md-4">
          <label class="d-block">Is the field Unique ?</label>
          <div class="custom-control custom-control-inline custom-radio">
            <input
              type="radio"
              value="true"
              name="update_form_field is_unique"
              id="is_unique_yes"
              class="custom-control-input"
              checked
              v-model="formFieldToUpdate.validation.is_unique"
              v-validate="'required'"
            />
            <label class="custom-control-label" for="is_unique_yes">Yes</label>
          </div>

          <div class="custom-control custom-control-inline custom-radio">
            <input
              type="radio"
              value="false"
              name="update_form_field is_unique"
              id="is_unique_no"
              class="custom-control-input"
              v-model="formFieldToUpdate.validation.is_unique"
            />
            <label class="custom-control-label" for="is_unique_no">No</label>
          </div>

          <small
            class="text-danger"
            v-if="errors.has('update_form_field is_unique')"
            >{{ errors.first('update_form_field is_unique') }}</small
          >
        </div>
        <div class="col-md-4">
          <label class="d-block">Allow Decimal ?</label>
          <div class="custom-control custom-control-inline custom-radio">
            <input
              type="radio"
              value="true"
              name="update_form_field allow_decimal"
              id="allow_decimal_yes"
              class="custom-control-input"
              checked
              v-model="formFieldToUpdate.validation.allow_decimal"
              v-validate="'required'"
            />
            <label class="custom-control-label" for="allow_decimal_yes"
              >Yes</label
            >
          </div>

          <div class="custom-control custom-control-inline custom-radio">
            <input
              type="radio"
              value="false"
              name="update_form_field allow_decimal"
              id="allow_decimal_no"
              class="custom-control-input"
              v-model="formFieldToUpdate.validation.allow_decimal"
            />
            <label class="custom-control-label" for="allow_decimal_no"
              >No</label
            >
          </div>

          <small
            class="text-danger"
            v-if="errors.has('update_form_field allow_decimal')"
            >{{ errors.first('update_form_field allow_decimal') }}</small
          >
        </div>
        <div class="col-md-4">
          <label class="d-block">Is field Required ?</label>
          <div class="custom-control custom-control-inline custom-radio">
            <input
              type="radio"
              value="true"
              name="update_form_field is_required"
              id="is_required_yes"
              class="custom-control-input"
              v-model="formFieldToUpdate.validation.is_required"
              v-validate="'required'"
            />
            <label class="custom-control-label" for="is_required_yes"
              >Yes</label
            >
          </div>

          <div class="custom-control custom-control-inline custom-radio">
            <input
              type="radio"
              value="false"
              name="update_form_field is_required"
              id="is_required_no"
              class="custom-control-input"
              v-model="formFieldToUpdate.validation.is_required"
            />
            <label class="custom-control-label" for="is_required_no">No</label>
          </div>

          <small
            class="text-danger"
            v-if="errors.has('update_form_field is_required')"
            >{{ errors.first('update_form_field is_required') }}</small
          >
        </div>
        <div class="col-md-6">
          <div class="form-group mg-t-20">
            <label for="regex">Regular expression #(num) $(string)</label>
            <input
              id="regex"
              placeholder="Regular expression #(num) $(string)"
              name="update_form_field regex"
              type="text"
              v-model="formFieldToUpdate.validation.regex"
              v-validate="'min:1|max:150'"
              class="form-control"
            />
            <small
              class="text-danger"
              v-if="errors.has('update_form_field regex')"
              >{{ errors.first('update_form_field regex') }}</small
            >
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group mg-t-20">
            <label for="length">Field Length</label>
            <input
              id="length"
              placeholder="Field Length"
              name="update_form_field length"
              type="text"
              v-model="formFieldToUpdate.validation.length"
              v-validate="'required|numeric'"
              class="form-control"
            />
            <small
              class="text-danger"
              v-if="errors.has('update_form_field length')"
              >{{ errors.first('update_form_field length') }}</small
            >
          </div>
        </div>
        <div class="col-md-4">
          <label class="d-block">All Capitalised?</label>
          <div class="custom-control custom-control-inline custom-radio">
            <input
              type="radio"
              value="true"
              name="update_form_field allCaps"
              id="allCaps_yes"
              class="custom-control-input"
              v-model="formFieldToUpdate.formating.allCaps"
              v-validate="'required'"
            />
            <label class="custom-control-label" for="allCaps_yes">Yes</label>
          </div>

          <div class="custom-control custom-control-inline custom-radio">
            <input
              type="radio"
              value="false"
              name="update_form_field allCaps"
              id="allCaps_no"
              class="custom-control-input"
              v-model="formFieldToUpdate.formating.allCaps"
            />
            <label class="custom-control-label" for="allCaps_no">No</label>
          </div>

          <small
            class="text-danger"
            v-if="errors.has('update_form_field allCaps')"
            >{{ errors.first('update_form_field allCaps') }}</small
          >
        </div>
        <div class="col-md-4">
          <label class="d-block">Allow Small Letters?</label>
          <div class="custom-control custom-control-inline custom-radio">
            <input
              type="radio"
              value="true"
              name="update_form_field allSmall"
              id="allSmall_yes"
              class="custom-control-input"
              checked
              v-model="formFieldToUpdate.formating.allSmall"
              v-validate="'required'"
            />
            <label class="custom-control-label" for="allSmall_yes">Yes</label>
          </div>

          <div class="custom-control custom-control-inline custom-radio">
            <input
              type="radio"
              value="false"
              name="update_form_field allSmall"
              id="allSmall_no"
              class="custom-control-input"
              v-model="formFieldToUpdate.formating.allSmall"
            />
            <label class="custom-control-label" for="allSmall_no">No</label>
          </div>

          <small
            class="text-danger"
            v-if="errors.has('update_form_field allSmall')"
            >{{ errors.first('update_form_field allSmall') }}</small
          >
        </div>
        <div class="col-md-6">
          <div class="form-group mg-t-20">
            <label for="min"
              >Field Minimum Length (for select min selectable)</label
            >
            <input
              id="min"
              placeholder="Field Minimum Length (for select min selectable)"
              name="update_form_field min"
              type="text"
              v-model="formFieldToUpdate.formating.min"
              v-validate="'required|numeric'"
              class="form-control"
            />
            <small
              class="text-danger"
              v-if="errors.has('update_form_field min')"
              >{{ errors.first('update_form_field min') }}</small
            >
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group mg-t-20">
            <label for="max">Field Maximum Length (eg for date 2020)</label>
            <input
              id="max"
              placeholder="Field Maximum Length (eg for date  2020)"
              name="update_form_field max"
              type="text"
              v-model="formFieldToUpdate.formating.max"
              v-validate="'required|numeric'"
              class="form-control"
            />
            <small
              class="text-danger"
              v-if="errors.has('update_form_field max')"
              >{{ errors.first('update_form_field max') }}</small
            >
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="position">Position</label>
            <input
              id="position"
              placeholder="Position"
              name="update_form_field position"
              type="text"
              v-model="formFieldToUpdate.position"
              v-validate="'required|numeric'"
              class="form-control"
            />
            <small
              class="text-danger"
              v-if="errors.has('update_form_field position')"
              >{{ errors.first('update_form_field position') }}</small
            >
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="size">Size</label>
            <select
              name="update_form_field size"
              id="size"
              v-model="formFieldToUpdate.class"
              v-validate="'required'"
              class="custom-select"
            >
              <option
                v-for="option in size_options"
                :value="option.val"
                :key="option.val"
              >
                {{ option.label }}
              </option>
            </select>
            <small
              class="text-danger"
              v-if="errors.has('update_form_field size')"
              >{{ errors.first('update_form_field size') }}</small
            >
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="thirdparty">Thirdparty</label>
            <select
              name="update_form_field thirdparty"
              id="thirdparty"
              v-model="formFieldToUpdate.Thirdparty"
              v-validate="'required'"
              class="custom-select"
            >
              <option
                v-for="option in thirdparty_options"
                :value="option.val"
                :key="option.val"
              >
                {{ option.label }}
              </option>
            </select>
            <small
              class="text-danger"
              v-if="errors.has('update_form_field thirdparty')"
              >{{ errors.first('update_form_field thirdparty') }}</small
            >
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="verification_type">Verification Type</label>
            <select
              name="update_form_field verification_type"
              id="verification_type"
              v-model="formFieldToUpdate.verification_type"
              v-validate="'required'"
              class="custom-select"
            >
              <option
                v-for="option in verification_type_options"
                :value="option.val"
                :key="option.val"
              >
                {{ option.label }}
              </option>
            </select>
            <small
              class="text-danger"
              v-if="errors.has('update_form_field verification_type')"
              >{{ errors.first('update_form_field verification_type') }}</small
            >
          </div>
        </div>
        <div class="col-md-3">
          <label class="d-block">Is Child of? ignore if not nested</label>
          <div class="custom-control custom-control-inline custom-radio">
            <input
              type="radio"
              value="true"
              name="update_form_field is_child_of"
              id="is_child_of_yes"
              class="custom-control-input"
              v-model="formFieldToUpdate.isNested.is_child"
              @click="isDisabled = false"
            />
            <label class="custom-control-label" for="is_child_of_yes"
              >Yes</label
            >
          </div>

          <div class="custom-control custom-control-inline custom-radio">
            <input
              type="radio"
              value="false"
              name="update_form_field is_child_of"
              id="is_child_of_no"
              class="custom-control-input"
              v-model="formFieldToUpdate.isNested.is_child"
              @click="isDisabled = true"
            />
            <label class="custom-control-label" for="is_child_of_no">No</label>
          </div>

          <small
            class="text-danger"
            v-if="errors.has('update_form_field is_child')"
            >{{ errors.first('update_form_field is_child') }}</small
          >
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label for="parent">Parent</label>
            <select
              name="update_form_field parent"
              id="parent"
              v-model="formFieldToUpdate.isNested.is_child_of"
              class="custom-select"
              :disabled="
                isDisabled || !formFieldToUpdate.isNested.is_child
                  ? true
                  : false
              "
            >
              <option
                v-for="option in parent_options"
                :value="option.val"
                :key="option.val"
              >
                {{ option.label }}
              </option>
            </select>
            <small class="text-danger" v-if="errors.has('update_form_field')">{{
              errors.first('update_form_field')
            }}</small>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="parent_code"
              >Parent Code (If field is child if not put n/a)</label
            >
            <input
              id="parent_code"
              placeholder="Parent Code (If field is child if not put n/a)"
              name="update_form_field parent_code"
              type="text"
              v-model="formFieldToUpdate.parent_code"
              v-validate="'required'"
              class="form-control"
            />
            <small
              class="text-danger"
              v-if="errors.has('update_form_field parent_code')"
              >{{ errors.first('update_form_field parent_code') }}</small
            >
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="show_on_option_value"
              >show_on_option_value ? ignore if not nested</label
            >
            <input
              id="show_on_option_value"
              placeholder="show_on_option_value ? ignore if not nested"
              name="update_form_field show_on_option_value"
              type="text"
              v-model="formFieldToUpdate.isNested.show_on_option_value"
              class="form-control"
            />
            <small class="text-danger" v-if="errors.has('update_form_field')">{{
              errors.first('update_form_field')
            }}</small>
          </div>
        </div>
      </div>
      <div class="buttons-area d-flex justify-content-end">
        <button
          class="btn btn-primary mg-r-10"
          @click="submit()"
          :disabled="update_done"
        >
          Update <span class="lds-ring" v-show="loading"></span>
        </button>
        <button
          class="btn btn-secondary"
          @click="showVerifyModal = true"
          :disabled="!update_done"
        >
          Verify <span class="lds-ring" v-show="loading"></span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  async created() {
    this.EditFormField = Models.EditFormField;
    this.formField = Models.formField;

    Event.$on('verifyModal:submit', async (payload) => {
      this.verify(payload);
    });

    Event.$on('verifyModal:close', async (payload) => {
      this.showVerifyModal = false;
    });
  },
  data() {
    return {
      type_options: [
        { val: 'text', label: 'Text' },
        { val: 'option', label: 'Option' },
        { val: 'attachement', label: 'Attachement' },
        { val: 'search', label: 'Search' },
      ],
      subType_Options: [
        { val: 'text', label: 'Text' },
        { val: 'textarea', label: 'Textarea' },
        { val: 'number', label: 'Number' },
        { val: 'email', label: 'Email' },
        { val: 'contact', label: 'Contact' },
        { val: 'calender', label: 'Calender' },
        { val: 'calender_from', label: 'Calender From' },
        { val: 'calender_to', label: 'Calender To' },
        { val: 'mobile', label: 'Mobile' },
        { val: 'image', label: 'Image' },
        { val: 'document', label: 'Document' },
        { val: 'select', label: 'Select' },
        { val: 'radio', label: 'Radio' },
        { val: 'checkbox', label: 'Checkbox' },
        { val: 'multiple_select', label: 'Multiple Select' },
        { val: 'multiple_select_other', label: 'Multiple Select With Other' },
        { val: 'date', label: 'Date Only' },
        { val: 'time', label: 'Time Only' },
        { val: 'date_time', label: 'Date and Time' },
        { val: 'color', label: 'Color' },
        { val: 'autocomplete', label: 'Autocomplete' },
        { val: 'yes_no', label: 'Yes/No Question' },
        { val: 'yes_qstn', label: 'Yes Question' },
        { val: 'no_qstn', label: 'No Question' },
        { val: 'slider', label: 'Slider' },
        { val: 'director', label: 'Director' },
        { val: 'location', label: 'Location' },
        { val: 'country', label: 'Country' },
        { val: 'crsp_Make', label: 'Car Make' },
        { val: 'crsp_Model', label: 'Car Model' },
        { val: 'pdf', label: 'Pdf' },
        { val: 'search_industry', label: 'Search Industry' },
        { val: 'search_banks', label: 'Search Banks' },
        { val: 'search_postal_code', label: 'Search Postal Codes' },
        { val: 'search_industry_jobs', label: 'Search Industry Jobs' },
        { val: 'search_underwriters', label: 'Underwriters' },
        { val: 'search_motor_cycle', label: 'Motor Cycles' },
        { val: 'search_lorries', label: 'Lorries' },
        { val: 'search_prime_movers', label: 'Prime Movers' },
        { val: 'search_trailers', label: 'Trailers' },
        { val: 'search_threewheeler', label: 'Three Wheelers' },
        { val: 'search_tractors', label: 'Tractors' },
      ],
      size_options: [
        { val: 'col-12', label: 'Full' },
        { val: 'col-6', label: 'Half' },
        { val: 'col-3', label: 'Quater' },
        { val: 'col-4', label: 'Third' },
        { val: 'col-2', label: 'Sixth' },
        { val: 'col-1', label: 'Twelveth' },
      ],
      thirdparty_options: [
        { val: 'IPRS', label: 'Iprs' },
        { val: 'NTSA', label: 'Ntsa' },
        { val: 'WORLDCHECK', label: 'Worldcheck' },
        { val: 'n/a', label: 'Not Applicable' },
      ],
      verification_type_options: [
        { val: 'AUTO', label: 'Auto' },
        { val: 'MANUAL', label: 'Manual' },
        { val: 'THIRDPARTY', label: 'Thirdparty' },
      ],
      parent_options: [],
      formField: {},
      formFields: [],
      formFieldToUpdate: {},
      audit_id: null,
      loading: false,
      update_done: false,
      showVerifyModal: false,
      isDisabled: false,
    };
  },
  methods: {
    async submit() {
      this.loading = true;

      this.$validator.validate(
        'update_form_field field_name',
        this.formFieldToUpdate.field_name
      );
      this.$validator.validate(
        'update_form_field field_label',
        this.formFieldToUpdate.field_label
      );
      this.$validator.validate(
        'update_form_field hint',
        this.formFieldToUpdate.hint
      );
      this.$validator.validate(
        'update_form_field field_description',
        this.formFieldToUpdate.field_description
      );
      this.$validator.validate(
        'update_form_field help_text',
        this.formFieldToUpdate.help_text
      );
      this.$validator.validate(
        'update_form_field type',
        this.formFieldToUpdate.type_info.type
      );
      this.$validator.validate(
        'update_form_field sub_type',
        this.formFieldToUpdate.type_info.sub_type
      );
      this.$validator.validate(
        'update_form_field values',
        this.formFieldToUpdate.values
      );
      this.$validator.validate(
        'update_form_field is_unique',
        this.formFieldToUpdate.validation.is_unique
      );
      this.$validator.validate(
        'update_form_field allow_decimal',
        this.formFieldToUpdate.validation.allow_decimal
      );
      this.$validator.validate(
        'update_form_field is_required',
        this.formFieldToUpdate.validation.is_required
      );
      this.$validator.validate(
        'update_form_field regex',
        this.formFieldToUpdate.validation.regex
      );
      this.$validator.validate(
        'update_form_field length',
        this.formFieldToUpdate.validation.length
      );
      this.$validator.validate(
        'update_form_field allCaps',
        this.formFieldToUpdate.formating.allCaps
      );
      this.$validator.validate(
        'update_form_field allSmall',
        this.formFieldToUpdate.formating.allSmall
      );
      this.$validator.validate(
        'update_form_field min',
        this.formFieldToUpdate.formating.min
      );
      this.$validator.validate(
        'update_form_field max',
        this.formFieldToUpdate.formating.max
      );
      this.$validator.validate(
        'update_form_field position',
        this.formFieldToUpdate.position
      );
      this.$validator.validate(
        'update_form_field size',
        this.formFieldToUpdate.class
      );
      this.$validator.validate(
        'update_form_field verification_type',
        this.formFieldToUpdate.verification_type
      );
      this.$validator.validate(
        'update_form_field thirdparty',
        this.formFieldToUpdate.Thirdparty
      );
      this.$validator.validate(
        'update_form_field parent_code',
        this.formFieldToUpdate.parent_code
      );

      await this.$nextTick();
      if (utils.isValid(this.errors.items, 'update_form_field')) {
        try {
          let res = await req.callServerSecure({
            resource: 'form_field',
            action: 'edit',
            data: {
              field_code: this.formFieldToUpdate.field_code,
              update: {
                values: this.formFieldToUpdate.values,
                field_name: this.formFieldToUpdate.field_name,
                field_label: this.formFieldToUpdate.field_label,
                hint: this.formFieldToUpdate.hint,
                class: this.formFieldToUpdate.class,
                position: this.formFieldToUpdate.position,
                help_text: this.formFieldToUpdate.help_text,
                type_info: {
                  type: this.formFieldToUpdate.type_info.type,
                  sub_type: this.formFieldToUpdate.type_info.sub_type,
                },
                field_description: this.formFieldToUpdate.field_description,
                icon: '',
                formating: {
                  allCaps: this.formFieldToUpdate.formating.allCaps,
                  allSmall: this.formFieldToUpdate.formating.allSmall,
                  is_decimal: '',
                  min: this.formFieldToUpdate.formating.min,
                  max: this.formFieldToUpdate.formating.max,
                },
                validation: {
                  is_unique: this.formFieldToUpdate.validation.is_unique,
                  allow_decimal: this.formFieldToUpdate.validation
                    .allow_decimal,
                  length: this.formFieldToUpdate.validation.length,
                  regex: this.formFieldToUpdate.validation.regex,
                  is_required: this.formFieldToUpdate.validation.is_required,
                },
                isNested: {
                  is_child_of: this.formFieldToUpdate.isNested.is_child_of,
                  show_on_option_value: this.formFieldToUpdate.isNested
                    .show_on_option_value,
                  is_child: this.formFieldToUpdate.isNested.is_child,
                },
                verification_type: this.formFieldToUpdate.verification_type,
                Thirdparty: this.formFieldToUpdate.Thirdparty,
                parent_code: this.formFieldToUpdate.parent_code,
              },
            },
            meta_data: {},
          });

          this.audit_id = res.data.result.audit_id;

          this.loading = false;
          this.update_done = true;
        } catch (err) {
          console.log('err', err);
          notification.notify('error', 'Form Field Error ', err);
        }
      } else {
        this.loading = false;
      }
    },
    fetchFormFields() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: 'form_field',
            action: 'list',
            data: {},
            meta_data: {},
          });
          let pages = this.formField.wizard.pages;
          this.parent_options = utils.appendOptions(
            res.data.result.form_fields,
            'field_code',
            'field_name'
          );

          this.formField.wizard.pages = pages;

          this.formFields = res.data.result.form_fields;

          this.formFields.forEach((field) => {
            if (field.field_code === this.$route.params.id)
              this.formFieldToUpdate = field;
          });

          resolve(true);
        } catch (err) {
          reject(err);
        }
      });
    },
    async verify(data) {
      try {
        let res = await req.callServerSecure({
          resource: 'form_field',
          action: 'verify',
          data: {
            field_code: this.formFieldToUpdate.field_code,
            remarks: data.remarks,
            audit_id: this.audit_id,
          },
          meta_data: {},
        });

        this.$router.push({
          name: 'Configs-System-formFields',
          params: {},
        });

        notification.notify(
          'success',
          'Form Field Success',
          `Form Field updated successfully`
        );
      } catch (err) {
        console.log('err', err);
        notification.notify('error', 'Form Field Error ', err);
      }
    },
    updateFormField(data) {
      return new Promise(async (resolve, reject) => {
        try {
          console.log(data);
          // let res = await req.callServerSecure({
          //   resource: "form_field",
          //   action: "list",
          //   data: {},
          //   meta_data: {},
          // });

          // this.isResourceLoaded = false;
          this.$router.push({
            name: 'Configs-System-formFields',
            params: {},
          });

          resolve(true);
        } catch (err) {
          reject(err);
        }
      });
    },
  },
  async mounted() {
    await this.fetchFormFields();
    // this.isResourceLoaded = true;
    Event.$emit('loader:show', false);
  },
  beforeDestroy() {
    Event.$off('verifyModal:submit');
  },
};
</script>

<style></style>
