

<script >
import { ComponentA } from '@frontend/components';
export default {
	 components: {
       ComponentA
     },
	 methods: {
	 },
	async mounted() {
		await this.$nextTick()
	
		Event.$emit('loader:show', false)
	}
};
</script>

<template lang="html">
    <div class="dashboard">
        <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
            <div>
				
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                        <li class="breadcrumb-item">
                            <router-link :to="{name: 'Dashboard'}">
                                Admin
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">Dashboard</li>
                    </ol>
                </nav>
                <h4 class="mg-b-0 tx-spacing--1">Welcome to Admin Dashboard</h4>
					  <h6 class="mg-b-0 tx-spacing--1"> Hi</h6>
				<ComponentA msg="Welcome to the new Dashboard"/>     
            </div>

         

            </div>


    </div>
</template>

<style lang="less">
.dashboard {
	#flotChart {
		width: 100%;
		// height: 380px;
	}
	#flotChart2 {
		width: 100%;
		// height: 225px;
    }
    .tx-big{
        font-size: 32px;
        font-weight: 600;
    }
    .tx-center{
        text-align: center;
    }
}
</style>