<template lang="html">
  <div class="Security Context">
    <div class="card mg-b-10 pd-40">
      <div class="card-header mg-b-40 d-sm-flex align-items-start justify-content-center bd-b-0 pd-b-0">
            <div class="table-title-icon">                  
                  <div class="f-left txt-box">
                     <div class="mg-b-5 fx-h5">Security Context</div>
                     <p class="tx-13 tx-color-03 mg-b-0">Add Security Context Configurartion</p>
                  </div>
            </div>
      </div>

      <div class="form-row">                    
        <div class="form-group col-md-5 offset-md-2">
          <label class="mg-r-20">Name:</label>
          <input type="text"   placeholder="Input Name" name="Security Context Name" id="name" class="form-control" v-model="name" v-validate="'required'">

          <small
            class="text-danger"
            v-if="errors.has('Security Context Name')"
          >{{errors.first('Security Context Name')}}</small>
        </div>                   
        <div class="form-group col-md-5">
          <label class="mg-r-20">Code:</label>
          <input type="text"   placeholder="Input Code" name="Security Context Code" id="security_context_code" class="form-control" v-model="security_context_code" v-validate="'required'">

          <small
            class="text-danger"
            v-if="errors.has('Security Context Code')"
          >{{errors.first('Security Context Code')}}</small>
        </div>
      </div>

      <div class="form-row">                    
        <div class="form-group col-md-10 offset-md-2">
          <label class="mg-r-20">Password Change Policy:</label>
          <div class="custom-control custom-control-inline custom-radio">
            <input type="radio"  value="WEEKLY" name="Security Context Password Policy" id="WEEKLY" class="custom-control-input" checked v-model="password_policy" v-validate="'required'">
            <label class="custom-control-label" for="WEEKLY">Weekly</label>
          </div>

          <div class="custom-control custom-control-inline custom-radio">
            <input type="radio" value="MONTHLY" name="Security Context Password Policy" id="MONTHLY" class="custom-control-input" v-model="password_policy">
            <label class="custom-control-label" for="MONTHLY">Monthly</label>
          </div>

          <div class="custom-control custom-control-inline custom-radio">
            <input type="radio" value="QUARTERLY" name="Security Context Password Policy" id="QUARTERLY" class="custom-control-input" v-model="password_policy">
            <label class="custom-control-label" for="QUARTERLY">Quarterly</label>
          </div>

          <div class="custom-control custom-control-inline custom-radio">
            <input type="radio" value="YEARLY" name="Security Context Password Policy" id="YEARLY" class="custom-control-input" v-model="password_policy">
            <label class="custom-control-label" for="YEARLY">Yearly</label>
          </div>
          <small
            class="text-danger"
            v-if="errors.has('Security Context Password Policy')"
          >{{errors.first('Security Context Password Policy')}}</small>
        </div>                                     
      </div>

      <div class="form-row">                    
        <div class="form-group col-md-10 offset-md-2">
          <label class="mg-r-20">Two Factor Authentication:</label>
          <div class="custom-control custom-control-inline custom-radio">
            <input type="radio"  value="true" name="two_factor_authentication" id="yes" class="custom-control-input" checked v-model="two_factor_authentication" v-validate="'required'">
            <label class="custom-control-label" for="yes">Yes</label>
          </div>

          <div class="custom-control custom-control-inline custom-radio">
            <input type="radio" value="false" name="two_factor_authentication" id="no" class="custom-control-input" v-model="two_factor_authentication">
            <label class="custom-control-label" for="no">No</label>
          </div>

          <small
            class="text-danger"
            v-if="errors.has('two_factor_authentication')"
          >{{errors.first('two_factor_authentication')}}</small>
        </div>                                     
      </div>

      <div class="form-row mg-t-30">
        <p class="tx-bold col-md-10 offset-md-2"> Idle Max Timeout: </p>
        <div class="d-flex col-md-10 offset-md-2">
          <div class="form-group d-flex align-items-center col-md-6"> 
            <div>
              <label class="mg-r-20">Select:</label>         
              <select class="custom-select wd-60" v-model="idle_max_timeout">
                <option
                  v-for="option in idleMinutes"
                  :value="option"
                  :key="option"
                  >{{option}}</option>                
              </select>
            </div>  
          </div>  
        </div>             
      </div>

      <div class="form-row mg-t-30">
        <p class="tx-bold col-md-10 offset-md-2"> Login Period: </p>
        <div class="d-flex col-md-10 offset-md-2">
          <div class="form-group d-flex align-items-center col-md-6"> 
            <div>
              <label class="mg-r-20">High:</label>         
              <select class="custom-select wd-60" v-model="high_hrs">
                <option
                  v-for="option in hours"
                  :value="option"
                  :key="option"
                  >{{option}}</option>                
              </select>
            </div>          
            <div class="mg-l-10">
              <div class="form-check">
                <input class="form-check-input tx-11" type="radio" v-model="high" name="high" id="am" value="am">
                <label class="form-check-label tx-11" for="am">
                  AM
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" v-model="high" name="high" id="pm" value="pm">
                <label class="form-check-label tx-11" for="pm">
                  PM
                </label>
              </div>
            </div>
          </div>
          <div class="form-group d-flex align-items-center col-md-6"> 
            <div>
              <label class="mg-r-20">Low:</label>         
              <select class="custom-select wd-60" v-model="low_hrs">            
                <option
                  v-for="option in hours"
                  :value="option"
                  :key="option"
                  >{{option}}</option> 
              </select>
            </div>          
            <div class="mg-l-10">
              <div class="form-check">
                <input class="form-check-input tx-11" type="radio" v-model="low" name="low" id="am" value="am">
                <label class="form-check-label tx-11" for="am">
                  AM
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" v-model="low" name="low" id="pm" value="pm">
                <label class="form-check-label tx-11" for="pm">
                  PM
                </label>
              </div>
            </div>
          </div>   
        </div>             
      </div>

      <div class="form-row">                    
        <div class="form-group col-md-10 offset-md-2">
          <label class="mg-r-20">Holiday Login:</label>
          <div class="custom-control custom-control-inline custom-radio">
            <input type="radio"  value="true" name="holiday_login" id="holiday_yes" class="custom-control-input" checked v-model="holiday_login" v-validate="'required'">
            <label class="custom-control-label" for="holiday_yes">Yes</label>
          </div>

          <div class="custom-control custom-control-inline custom-radio">
            <input type="radio" value="false" name="holiday_login" id="holiday_no" class="custom-control-input" v-model="holiday_login">
            <label class="custom-control-label" for="holiday_no">No</label>
          </div>

          <small
            class="text-danger"
            v-if="errors.has('holiday_login')"
          >{{errors.first('holiday_login')}}</small>
        </div>                                     
      </div>

      <div class="form-row mg-t-30">
        <p class="tx-bold col-md-10 offset-md-2"> Allowed Channel: </p>
        <div class="col-md-10 offset-md-2">
          <div class="form-group d-flex align-items-center"> 
            <div class="mg-l-10">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" v-model="allowed_channels" id="web" value="web">
                <label class="form-check-label" for="web">Web</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" v-model="allowed_channels" id="mobile" value="mobile">
                <label class="form-check-label" for="mobile">Mobile</label>
              </div>
            </div>
          </div>             
        </div>             
      </div>

      <div class="form-row mg-t-30">
        <p class="tx-bold col-md-10 offset-md-2"> Allowed Browsers: </p>
        <div class="col-md-10 offset-md-2">
          <div class="form-group d-flex align-items-center"> 
            <div class="mg-l-10">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" v-model="allowed_devices" id="chrome" value="chrome">
                <label class="form-check-label" for="chrome">Google Chrome</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" v-model="allowed_devices" id="firefox" value="firefox">
                <label class="form-check-label" for="firefox">Firefox</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" v-model="allowed_devices" id="edge" value="edge">
                <label class="form-check-label" for="edge">Microsoft Edge</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" v-model="allowed_devices" id="safari" value="safari">
                <label class="form-check-label" for="safari">Safari</label>
              </div>
            </div>
          </div>             
        </div>             
      </div>

      <div class="form-row mg-t-30">
        <div class="form-group d-flex align-items-center col-md-10 offset-md-2">
          <label class="mg-r-20">Browser Versions:</label>         
              <select class="custom-select wd-60" v-model="device_versions">            
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>                
              </select>
        </div>
      </div>

      <div class="form-row">                    
        <div class="form-group col-md-10 offset-md-2">
          <label class="mg-r-20">Description:</label>
          <textarea type="text"   row="3" placeholder="Description" name="Security Context Description" id="description" class="form-control" v-model="description" v-validate="'required'"></textarea>

          <small
            class="text-danger"
            v-if="errors.has('Security Context Description')"
          >{{errors.first('Security Context Description')}}</small>
        </div>
      </div>

      <div class="d-flex justify-content-between col-md-9 offset-md-2 mg-t-40">
        <button class="btn btn-primary wd-200" v-if="isUpdating">Update </button> 
        <button class="btn btn-primary wd-200" @click="saveSecurityContext()" v-else>Save </button>                                             
        <button class="btn btn-secondary  wd-200" @click="close()">Close </button> 
      </div>                      
    </div>
  </div>
</template>

<script>
export default {
  async created() {
    this.hours = [...Array(12).keys()].map((i) => i + 1);
    this.idleMinutes = [...Array(60).keys()].map((i) => i + 5);
  },
  data() {
    return {
      name: '',
      description: '',
      password_policy: "",
      security_context_code: "",
      low: "pm",
      high: "am",
      high_hrs: "10",
      low_hrs: "10",
      hours: [],
      idleMinutes: [],
      idle_max_timeout: 10,
      allowed_channels: ["web"],
      allowed_devices: ["chrome"],
      device_versions: '1',
      two_factor_authentication: false,
      holiday_login: false,
      category: "",
      channel: "",
      token_ttl: 1,
      isUpdating: false
    };
  },
  computed: {
    deviceVersions () {
      return this.allowed_devices.reduce((acc, item) => {
        const obj = {}
        obj['device_type'] = item;
        obj['device_version'] = this.device_versions
        acc.push(obj)
        return acc;
      }, [])
    }
  },
  methods: {
    close() {
      this.$router.push({
        name: "list_security_context",
        params: {},
      });
    },
    async saveSecurityContext() {
      this.$validator.validate(
        "Security Context Password Policy",
        this.password_policy
      );
      this.$validator.validate(
        "Security Context Name",
        this.name
      );
      this.$validator.validate(
        "Security Context Code",
        this.security_context_code
      );
      this.$validator.validate(
        "Security Context Description",
        this.description
      );
      console.log("errr")

     await this.$nextTick();
      if (utils.isValid(this.errors.items, "Security Context")) {
          console.log("iside", this.deviceVersions)
        try {
          let res = await req.callServerSecure({
            resource: "security_context",
            action: "create",
            data: {
              name: this.name,
              description: this.description,
              password_change_policy: this.password_policy,
              idle_max_timeout: this.idle_max_timeout,
              token_ttl: this.token_ttl,
              security_context_code: this.security_context_code,
              login_context: {
                  high: `${this.high_hrs} ${this.high}`,
                  low: `${this.low_hrs} ${this.low}`
              },
              allowed_channels: this.allowed_channels,
              allowed_devices: this.allowed_devices,
              device_versions: this.deviceVersions,
              two_factor_authentication: this.two_factor_authentication,
              holiday_login: this.holiday_login
            },
            meta_data: {},
          });
          console.log("res", res)

          this.$router.push({
            name: "add_security_context",
            params: {},
          });
          notification.notify(
            "success",
            "Security Context Success",
            `Security Context created successfully`
          );
        } catch (err) {
          console.log("err", err)
          notification.notify("error", "Security Context Error ", err);
        }
      } else {
        console.log("else")
        this.loading = false;
      }
    },
    async getSecurityContexts() {
      try {
        return new Promise(async (resolve, reject) => {
          let res = await req.callServerSecure({
            resource: "security_context",
            action: "get",
            data: {
              "security_context_id" : this.$route.params.id
            },
            meta_data: {},
          });

          console.log(res.data.result.security_context_details)
          const securityContext = res.data.result.security_context_details;
          this.name = securityContext.name,
          this.description = securityContext.description,
          this.password_policy = securityContext.password_change_policy,
          this.security_context_code = securityContext.security_context_code,
          this.low = securityContext.login_context.low.split(" ")[1],
          this.high = securityContext.login_context.high.split(" ")[1],
          this.high_hrs = securityContext.login_context.high.split(" ")[0],
          this.low_hrs = securityContext.login_context.low.split(" ")[0],
          this.idle_max_timeout = securityContext.idle_max_timeout,
          this.allowed_channels = securityContext.allowed_channels,
          this.allowed_devices = securityContext.allowed_devices,
          this.device_versions = securityContext.device_versions[0].device_version,
          this.two_factor_authentication = securityContext.two_factor_authentication,
          this.holiday_login = securityContext.holiday_login,

          resolve();
        });
      } catch (err) {
        reject(err);
      }
    },
  },

  async mounted() {
    if(this.$route.params.id) {
      this.isUpdating = true;
      await this.getSecurityContexts()
    }
    Event.$emit("loader:show", false);
  },
};
</script>

<style lang="less">
</style>
