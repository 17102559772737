<template lang="html">
   <div class="claimCategories">
      <ModalForm :showModal="showModal" :action="ClaimCategory.wizard.action">
            <template slot="form">
               <CustomForm 
                  :pages="ClaimCategory.wizard.pages" 
                  :action="ClaimCategory.wizard.action" 
                  :buttonText="ClaimCategory.wizard.actionBtn" 
                  :showTitle="false">
               </CustomForm>
            </template>
      </ModalForm>

      <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
         <div></div>
         <div class="d-none d-md-block">
            <button class="btn btn-sm pd-x-15 btn-white btn-uppercase" @click="openAddClaimCategoryModal()">
               <i data-feather="mail" class="wd-10 mg-r-5"></i> Add Claim Category
            </button>
            <!-- <button class="btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5">
               <i data-feather="printer" class="wd-10 mg-r-5"></i> Download Report
            </button> -->
         </div>
      </div>

      <div class="contents">
         <CustomTable :tableFields="ClaimCategory.table.table_fields" 
            :tableData="claimCategories" 
            :title="ClaimCategory.table.title" 
            :subtitle="ClaimCategory.table.subtitle" 
            :searchFields="ClaimCategory.table.searchFields"
            :rowData="ClaimCategory.table.rowData">
         </CustomTable>
      </div>
   </div>
</template>

<script>
export default {
	async created() {
		this.ClaimCategory = Models.ClaimCategories;
		let _this = this;

		this.claimCategories = [];

		// if specified action is complete clear form data and stop loader
		Event.$on("form:submit", async payload => {
			if (payload.action === this.ClaimCategory.wizard.action) {
				await this.addClaimCategory(payload.formData);
				this.showModal = false;
			}
		});

		Event.$on("modal:close", async payload => {
			console.log("Closing modal");
			this.showModal = false;
		});

		Event.$on("ClaimCategory:view", payload => {
			this.$router.push({
				name: "Configs-Insurance-ViewClaimCategory",
				params: { id: payload.ClaimCategory.claim_type_code }
			});
		});
	},
	data() {
		return {
			ClaimCategory: {},
			claimCategories: [],
			formData: {},
			showModal: false
		};
	},
	methods: {

      fetchclaimCategories () {
         return new Promise(async (resolve, reject) => {
				try {
					let res = await req.callServerSecure({
						resource: "underwritter_claim_categories",
						action: "list",
						data: {},
						meta_data: {}
					});

					console.log(" === Claim Categories == ",res.data.result);

               this.claimCategories = res.data.result.claim_category;
					resolve(true);
				} catch (err) {
					reject(err);
				}
			});
      },
		openAddClaimCategoryModal() {
			this.showModal = true;
		},
		addClaimCategory(data) {
			return new Promise(async (resolve, reject) => {
				try {
					let res = await req.callServerSecure({
						resource: "underwritter_claim_categories",
						action: "create",
						data: {
						         code :data.code,
                           name :data.name,
                           description :data.description
						}
					});

               await this.fetchclaimCategories()
					notification.notify("success","Claim Categories Added",`Claim Category ${data.name
					} successfully added`);
					Event.$emit("form:action-complete", {action: this.ClaimCategory.wizard.action,clear: true});
					this.showModal = false;
				} catch (err) {
					console.log(err);
					Event.$emit("form:action-error", {action: this.ClaimCategory.wizard.action});
				}
			});
		}
	},
	async mounted() {
      await this.fetchclaimCategories()
		Event.$emit("loader:show", false);
	}
};
</script>

<style lang="less">
.claimCategories {
}
</style>