<template lang="html">
  <div class="view-product view">
    <div class="row row-xs">
      <div class="col-lg-12 col-xl-12">
        <div class="tab-navigator">
          <div class="navigator clearfix">
            <div
              class="btn-nav"
              :class="{ active: tab === 1 }"
              @click="showTab(1)"
            >
              <i class="fa fa-coffee"></i> Details
            </div>
            <div
              class="btn-nav"
              :class="{ active: tab === 2 }"
              @click="showTab(2)"
            >
              <i class="fa fa-coffee"></i>Underwriter  Products
            </div>
            <div
              class="btn-nav"
              :class="{ active: tab === 3 }"
              @click="showTab(3)"
            >
              <i class="fa fa-coffee"></i> Directors &amp; Super Users
            </div>
            <div
              class="btn-nav"
              :class="{ active: tab === 4 }"
              @click="showTab(4)"
            >
              <i class="fa fa-coffee"></i> Reviews
            </div>
            <div
              class="btn-nav"
              :class="{ active: tab === 5 }"
              @click="showTab(5)"
            >
              <i class="fa fa-coffee"></i> Authorised Classes
            </div>
          </div>

          <ModalForm :showModal="showClassModal">
            <template slot="form">
              <ul class="list-group">
                <span class="d-block tx-11 text-muted"
                  >Click on the List,which you want added</span
                >
                <li
                  v-for="(item, index) in sub_classes"
                  class="list-group-item"
                >
                  <span class="d-block tx-11 text-muted">
                    Name : {{ item.name }}</span
                  >
                  <span class="d-block tx-11 text-muted">
                    Ira Code : {{ item.ira_code }}</span
                  >
                  <span class="d-block tx-11 text-muted"
                    >Description : {{ item.description }}</span
                  >
                  <span
                    class="d-block tx-11 text-muted"
                    @click="SaveUnderwriterClass(item)"
                    >Click here to add : {{ item.name }}</span
                  >
                </li>
              </ul>
            </template>
          </ModalForm>

          <div class="pages">
            <div class="page" v-show="tab === 1">
              <div class="top-content clearfix">
                <div class="float-l left-content">
                  <div class="profile">
                    <img
                      :src="
                        getImg(underwriter_details.underwriter_details.logo)
                      "
                      alt=""
                      class="profile-img"
                    />
                  </div>
                </div>
                <div class="float-l right-content">
                  <div class="main-details">
                    <div class="name">
                      {{ underwriter_details.underwriter_details.name }}
                    </div>
                    <div class="number">
                      {{ underwriter_details.underwriter_details.category }}
                    </div>
                    <div class="text">
                      {{ underwriter_details.underwriter_details.description }}
                    </div>
                  </div>
                  <div class="stats clearfix">
                    <div class="float-l stat">
                      <div class="big float-l">
                        {{ underwriter_details.underwriter_details.rating }}
                      </div>
                      <div class="small float-l">Rating</div>
                    </div>
                    <div class="float-l stat">
                      <div class="big float-l">
                        {{ underwriter_details.underwriter_details.ranking }}
                      </div>
                      <div class="small float-l">Ranking</div>
                    </div>
                  </div>

                  <div class="actions">
                    <div class="float-l btn-action pink" title="Edit">
                      <i class="icon icon-briefcase"></i>
                    </div>
                    <div class="float-l btn-action orange" title="Verify User">
                      <i class="icon icon-calendar"></i>
                    </div>
                    <div class="float-l btn-action green">
                      <i class="icon icon-email"></i>
                    </div>
                    <div class="float-l btn-action blue">
                      <i class="icon icon-exchange"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="more-details">
                <div class="title">More Details</div>
                <div class="row">
                  <div class="col-12 col-lg-3 col-md-6">
                    <label>Email</label>
                    <div class="data-field">
                      {{ underwriter_details.underwriter_details.emails }}
                    </div>
                  </div>
                  <div class="col-12 col-lg-3 col-md-6">
                    <label>Contacts</label>
                    <div class="data-field">
                      {{ underwriter_details.underwriter_details.contacts }}
                    </div>
                  </div>
                  <div class="col-12 col-lg-3 col-md-6">
                    <label>Ira Code</label>
                    <div class="data-field">
                      {{ underwriter_details.underwriter_details.ira_code }}
                    </div>
                  </div>
                  <div class="col-12 col-lg-3 col-md-6">
                    <label>Group Code</label>
                    <div class="data-field">
                      <a href="javascript:void()">{{
                        underwriter_details.underwriter_details.group_code
                      }}</a>
                    </div>
                  </div>
                  <div class="col-12 col-lg-3 col-md-6">
                    <label>Reg Status</label>
                    <div class="data-field">
                      {{
                        underwriter_details.underwriter_details
                          .registration_status
                      }}
                    </div>
                  </div>
                  <div class="col-12 col-lg-3 col-md-6">
                    <label>Profile Status</label>
                    <div class="data-field">
                      {{
                        underwriter_details.underwriter_details.profile_status
                      }}
                    </div>
                  </div>
                  <div class="col-12 col-lg-3 col-md-6">
                    <label>Reg Date</label>
                    <div class="data-field">
                      {{ underwriter_details.underwriter_details.created_date }}
                    </div>
                  </div>
                  <div class="col-12 col-lg-3 col-md-6">
                    <label>Reg By</label>
                    <div class="data-field">
                      <a href="javascript:void()">{{
                        underwriter_details.underwriter_details.activated_by
                      }}</a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="tables">
                <div class="title">Wallets</div>

                <CustomTable
                  :tableFields="Wallet.table.table_fields"
                  :icon="Wallet.table.icon"
                  :title="Wallet.table.title"
                  :subtitle="Wallet.table.subtitle"
                  :tableData="wallets"
                  :searchFields="Wallet.table.searchFields"
                  :rowData="Wallet.table.rowData"
                >
                </CustomTable>
              </div>
            </div>
            <div class="page" v-show="tab === 2">
              <div class="title">Products</div>
              <CustomTable
                :tableFields="UnderwritterProducts.table.table_fields"
                :tableData="products"
                :title="UnderwritterProducts.table.title"
                :subtitle="UnderwritterProducts.table.subtitle"
                :searchFields="UnderwritterProducts.table.searchFields"
                :rowData="UnderwritterProducts.table.rowData"
              >
              </CustomTable>
            </div>
            <div class="page" v-show="tab === 3">
              <div class="mg-b-40">
                <div class="title">Super Users</div>
                <UnderwritterSuperUsers />
              </div>

              <div class="title">Directors</div>
              <UnderwritterDirectors />
            </div>
            <div class="page" v-show="tab === 4">
              <UnderwritterReviews />
            </div>
            <div class="page" v-show="tab === 5">
              <div class="row">
                <div class="col-sm">
                  <button
                    type="button"
                    class="btn btn-outline-dark pull-right"
                    @click="addUnderwriterClass()"
                  >
                    Add Class
                  </button>
                  <h4 id="section1" class="mg-b-10">Authorised Classes</h4>
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Class Code</th>
                        <th scope="col">Ira Code</th>
                        <th scope="col">Name</th>
                        <th scope="col">Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in authorisedClasses">
                        <th scope="row">{{ index }}</th>
                        <td>{{ item.class_code }}</td>
                        <td>{{ item.ira_code }}</td>
                        <td>{{ item.name }}</td>
                        <td>{{ item.description }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  async created() {
    this.UnderwritterProducts = Models.UnderwritterProducts;
    this.CompanyProfile = Models.CompanyProfile;
    this.Wallet = Models.Wallet;

    Event.$on('form:submit', async (payload) => {
      if (payload.action === this.CompanyProfile.wizard.action) {
        await this.updateCompanyProfile(payload.formData);
        this.showModal = false;
      }
    });
  },
  data() {
    return {
      CompanyProfile: {},
      showModal: false,
      showClassModal: false,
      tab: 1,
      underwriter_details: {
        underwriter_details:{}
      },
      wallets: [],
      authorisedClasses: [],
      sub_classes: [],
      selectedUnderwriterClass: [],
      products: [],
    };
  },
  methods: {
    showTab(tab) {
      this.tab = tab;
    },
    getImg(path) {
      if (path) {
        console.log(" === GET IMAGE +++++ ",path)
        return req.getParsedUrl(path);
      } else {
        return '/img/placeholder.jpg';
      }
    },
    removeClass() {},
    addUnderwriterClass() {
      this.showClassModal = true;
    },
    async SaveUnderwriterClass(classCode) {
      await this.addInsuranceClassToUnderwriter(classCode.ira_code);
      this.showClassModal = false;
    },
    async addInsuranceClassToUnderwriter(classCode) {
      return new Promise(async (resolve, reject) => {
        try {
          Event.$emit('loader:show', true);
          let res = await req.callServerSecure({
            resource: 'underwritter',
            action: 'add_authorised_class',
            data: {
              underwritter_code: this.underwriter_details.underwriter_details
                .code,
              uclass: classCode,
            },
          });
          await this.fetchunderwriterAuthorisedClasses();
          Event.$emit('loader:show', false);
          notification.notify(
            'success',
            'Insurance Class',
            `Insurance Succeffuly added to Underwriter `
          );
          return resolve();
        } catch (err) {
          Event.$emit('loader:show', false);
          notification.notify(
            'error',
            ' Error Adding Insurance to Underwriter ',
            err
          );
          return resolve();
        }
      });
    },
    async fetchunderwriter() {
      console.log(" ===FETCHING Underwriter Details ====== ")
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: 'underwritter',
            action: 'underwritter_summary',
            data: {
              underwritter_id: this.$route.params.id,
            },
            meta_data: {},
          });

         // console.log(" ===Underwriter Details ====== ",res.data.result)
          this.underwriter_details = res.data.result;
          this.products = this.underwriter_details.underwriter_products;
          await this.fetchunderwriterAuthorisedClasses();
          resolve(true);
        } catch (err) {
          reject(err);
        }
      });
    },
    fetchSubClasses() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await req.callServerSecure({
            resource: 'insurance_sub_class',
            action: 'list',
            data: {},
            meta_data: {},
          });

          this.sub_classes = res.data.result.insurance_sub_classes;
          resolve(true);
        } catch (err) {
          reject(err);
        }
      });
    },
    async fetchunderwriterAuthorisedClasses() {
      return new Promise(async (resolve, reject) => {
        try {
          // console.log(" underwriter code ",this.underwriter_details.underwriter_details.code)
          let res = await req.callServerSecure({
            resource: 'underwritter',
            action: 'underwritter_classes',
            data: {
              code: this.underwriter_details.underwriter_details.code,
            },
            meta_data: {},
          });

          this.authorisedClasses = res.data.result.underwritter_sub_classes;
          resolve(true);
        } catch (err) {
          reject(err);
        }
      });
    },
  },
  async mounted() {
    await this.fetchunderwriter();
    await this.fetchSubClasses();
    Event.$emit('loader:show', false);
  },
};
</script>

<style lang="less">
.view-product {
  .title {
    font-weight: 600;
    font-size: 20px;
    margin: 10px 0 20px 0;
    color: #7d89a1;
  }
  .more-details {
    margin-top: 50px;

    .data-field {
      margin-bottom: 20px;
    }
  }
  .tab-navigator .navigator .btn-nav {
    padding: 11px 0;
    text-align: center;
    border-left: solid 1px #efefef;
    width: 20%;
    float: left;
    font-weight: 600;
    cursor: pointer;
  }
}
</style>
