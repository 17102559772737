<template lang="html">
   <div class="accounts">
      <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
         <div class="query">
            <select class="custom-select" 
               v-model="entity_type"
               v-validate="'required'" 
               name="account query entity">
                  <option value="CLIENT">Client</option>
                  <option value="UNDERWRITTER">Underwritter</option>
            </select>
            <button class="btn btn-primary"
               @click="queryAccounts()"
               :disabled="loading">
                  Query <div class="lds-ring" v-show="loading"><div></div><div></div><div></div><div></div></div>
            </button>
         </div>
         <div class="d-none d-md-block">
               <button class="btn btn-sm pd-x-15 btn-white btn-uppercase" @click="addAccount()">
                  <i data-feather="mail" class="wd-10 mg-r-5"></i> Add Account Template
               </button>
         </div>
      </div>

      <CustomTable :tableFields="Accounts.table.table_fields"
         :icon="Accounts.table.icon"
         :title="Accounts.table.title"
         :subtitle="Accounts.table.subtitle"
         :tableData="accounts" 
         :searchFields="Accounts.table.searchFields"
         :rowData="Accounts.table.rowData">
      </CustomTable>
   </div>
</template>

<script>
export default {
	async created() {
		this.Accounts = Models.Accounts;

		// if specified action is complete clear form data and stop loader
		Event.$on("form:submit", async payload => {
			if (payload.action === this.Accounts.wizard.action) {
				await this.saveAccount(payload.formData);
				this.showModal = false;
			}
		});

		Event.$on("modal:close", async payload => {
			this.showModal = false;
		});

		Event.$on('account:view',(payload) => {
			this.$router.push({name: 'Configs-Accounting-ViewAccount',params: {id: payload.account.account_number}})
		})
	},
	data() {
		return {
			Accounts: {},
			accounts: [],
			showModal: false,
			entity_type: "CLIENT",
			loading: false
		};
	},
	methods: {
		addAccount() {
			this.showModal = true;
		},
		saveAccount(data) {
			return new Promise(async (resolve, reject) => {
				try {
					let res = await req.callServerSecure({
						resource: "entity_account_template",
						action: "add",
						data: {
							template_code: data.code,
							sub_gl_code: data.gl,
							account_limit_code: data.limit,
							account_status_code: data.status,
							account_statement_code: data.statement,
							currency_code: data.currency,
							entity_type: data.entity,
							entity_sub_type: data.entity_subtype,
							account_type: data.type
						},
						meta_data: {}
					});

               notification.notify('success', 'Accounts Template Added', `The account template ${data.name} was successfully added`)
               Event.$emit('form:action-complete',{action: this.Accounts.wizard.action, clear: true})
               this.showModal = false
				} catch (err) {
					console.log(err);
					Event.$emit("form:action-error", {
						action: this.Accounts.wizard.action
					});
				}
			});
		},
		queryAccounts() {
			return new Promise(async (resolve, reject) => {
				try {
					this.loading = true;

					let res = await req.callServerSecure({
						resource: "accounts",
						action: "entity_accounts",
						data: {
							entity_type: this.entity_type
						},
						meta_data: {}
					});

					this.accounts = res.data.result.accounts;
					this.loading = false;
				} catch (err) {
					this.loading = false;
				}
			});
		}
	},
	async mounted() {
		Event.$emit("loader:show", false);
	}
};
</script>

<style lang="less">
.accounts {
	.query {
		display: flex;
		justify-content: left;

		.btn {
			margin: 0 0 0 20px;
			text-align: center;
			display: flex;
			justify-content: center;
		}
		.custom-select {
			float: left;
			min-width: 200px;
		}
	}
}
</style>