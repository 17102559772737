
<template lang="html">
   <div class="addClientType">
              
           <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
            <div>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                        <li class="breadcrumb-item">
                            <router-link :to="{name: 'Dashboard'}">
                                Admin
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">Add Client Type</li>
                    </ol>
                </nav>    
            </div>  
        </div>
    

             <div class="row">
               <div class="col-sm-8 offset-sm-2">


                    <div class="form-row">
                      <div class="form-group col-md-5">
                    <nav class="nav nav-icon-only">
                     <div class="dropdown">
                       <button class="btn btn-xs btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Select Icon</button>
                     
                        <div class="dropdown-menu dropdown-menu-left" aria-labelledby="dropdownMenuButton">
                            <img :src="getImg(icon.file_path)"  class="img wd-60 ht-60 rounded-circle img-thumbnail"  :alt="icon.originalname" v-for="(icon, index) in icons" @click="iconSelected(icon.file_path)">
                         </div>
                            </div>  
                       </nav> 
                     </div>
                        <div class="form-group col-md-5">
                         <img  v-bind:src="clientType.icon" class="img wd-60 ht-60 rounded-circle" alt="">
                          </div>
                   </div>
                   
            
                   <div class="form-row">
                      <div class="form-group col-md-6">
                       <label>Code</label> 
                       <input type="text" class="form-control" placeholder="Client type code" name="clientTypeCode" id="clientTypeCode" v-model="clientType.code"   v-validate="'required'">
                       <div v-if="errors.has('clientTypeCode')" class="text-danger">{{ errors.first('clientTypeCode') }}</div>
                     </div>
                      <div class="form-group col-md-6">
                       <label>Name</label> 
                        <input type="text" class="form-control" placeholder="Client type name" name="clientTypename" id="clientTypename" v-model="clientType.name"   v-validate="'required'">
                       <div v-if="errors.has('clientTypename')" class="text-danger">{{ errors.first('clientTypename') }}</div>
                     </div>
                   </div>


                    <div class="form-row">
                      <div class="form-group col-md-12">
                       <label>Description</label> 
                      <textarea class="form-control" rows="2" placeholder="Client Type Description " name="clientTypedesc" id="clientTypedesc" v-model="clientType.description"   v-validate="'required'"></textarea>
                       <div v-if="errors.has('clientTypedesc')" class="text-danger">{{ errors.first('clientTypedesc') }}</div>
                     </div>
                   </div>

                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <button type="button" class="btn btn-primary" @click="saveClientType()">Save</button>
                     </div>
                      <div class="form-group col-md-6">
                          <button type="button" class="btn btn-warning" @click="clearClientType()">Clear</button>
                     </div>
                   </div>


               </div>  
            </div>

 </div>
</template>

<script>
export default {
	async created() {
    },
    data() {
		return {
            icons:[],
            imageSelected: false,
            clientType:{
                name:'',
                code:'',
                description:'',
                icon:''
            }
        }
    },
    methods: {
        getImg(path) {
            return req.getParsedUrl(path)
        },
        iconSelected (icon) {
        this.clientType.icon=req.getParsedUrl(icon)
        },
        selectIcon () {
            alert(" selecting")
           
        },
        async saveClientType () {
            try {
             
                    let valii= await this.$validator.validate();
                     if(!valii) {
                       return reject(" Validation Error ");
                     }
                   	let res = await req.callServerSecure({
						resource: "client_type",
						action: "add",
						data: {
							type_code: this.clientType.code,
							type_name: this.clientType.name,
							type_description: this.clientType.description,
                            type_icon:this.clientType.icon,
						}
					});
					notification.notify("success","Client Type Added",`Client Type ${this.clientType.name} successfully added`);
                    this.$router.push({
                        name: "Configs-Insurance-ClientType",
                        params: {}
                    });

            }
            catch (err){
         	console.log(err);
					Event.$emit("form:action-error", 'An Error Has Occured');
            }

        },
        clearClientType () {
          this.clientType={}
        },
         getIcons () {
            return new Promise(async (resolve,reject) => {
               try {
                  let res = await req.callServerSecure({
                     resource: "icons",
                     action: "list",
                     data: {
                 
                     },
                     meta_data: {},
                  });
                  this.icons = res.data.result
                  resolve(true)
               } catch (err) {
                  reject(err)
               }
            })
         }
    },
    async mounted() {
        await this.getIcons()
		Event.$emit("loader:show", false);
	}
}  
</script>
<style lang="less">
.addClientType {
}
</style>