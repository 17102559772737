<template>
  <div class="contact-content">
    <div class="contact-content-header">
      <nav class="nav nav-line">
        <a href="#details" class="nav-link active" data-toggle="tab"
          > Configuration Summary</a
        >
        <a href="#accountConfig" class="nav-link" data-toggle="tab"
          >Account Template Configuration</a
        >
        <a href="#feeConfig" class="nav-link" data-toggle="tab"
          >Fee Configuration</a
        >
        <a href="#securityConfig" class="nav-link" data-toggle="tab"
          >Security Configuration</a
        >
      </nav>
      <a href="" id="contactOptions" class="text-secondary mg-l-auto d-xl-none"
        ><i data-feather="more-horizontal"></i
      ></a>
          <div class="contact-content-body bg-gray-100">
              <div class="tab-content">
                 
                  <div id="details" class="tab-pane show active pd-20 pd-xl-25">
                       <h6 class="mg-t-10 mg-b-20">Details</h6>
                     
                       
                        

                        <ul class="list-group">
                          <li class="list-group-item"> Account Opening Setup  <span class="badge badge-warning"> Pending</span></li>
                          <li class="list-group-item">Fees  Setup <span class="badge badge-warning"> Pending</span></li>
                          <li class="list-group-item">Security  Setup <span class="badge badge-warning"> Pending</span></li>
                        </ul>
                            <div class="form-row">
                                  <div class="form-group col-md-4">
                                   <label>Default Account Opening Currency </label>
                                      <select
                                          class="custom-select"
                                          v-model="entityDefault.currency"
                                        >
                                          <option
                                            v-for="curr in currencies"
                                            v-bind:value="curr.currency_iso_code"
                                          >
                                            {{ curr.name }}
                                          </option>
                                        </select>
                                  </div>
                        </div>
                                    <button class="btn btn-primary" @click="saveDefaultEntityConfig()"> Save Default </button>
                   </div>

                   <div id="accountConfig" class="tab-pane  pd-20 pd-xl-25">
                        <h6 class="mg-t-10 mg-b-20">Account Templates</h6>
                            <div class="form-row">
                                <div class="form-group col-md-4">
                                  <label>Select Currency</label>
                                      <select
                                          class="custom-select"
                                          v-model="accounttemplates.currency"
                                        >
                                          <option
                                            v-for="curr in currencies"
                                            v-bind:value="curr.currency_iso_code"
                                          >
                                            {{ curr.name }}
                                          </option>
                                        </select>
                                 </div>
                                  <div class="form-group col-md-4">
                                    <label>Select Client Subtype</label>
                                      <select
                                          class="custom-select"
                                          v-model="accounttemplates.client_sub_type"
                                        >
                                          <option
                                            v-for="clientsubtype in clientsubtypes"
                                            v-bind:value="clientsubtype.subtype_code"
                                          >
                                            {{ clientsubtype.subtype_name }}
                                          </option>
                                        </select>
                                 </div>
                                  <div class="form-group col-md-4">
                                    <label>Select Account Opening Template</label>
                                      <select
                                          class="custom-select"
                                          v-model="accounttemplates.account_opening_template"
                                        >
                                          <option
                                            v-for="accountTemplate in accountTemplates"
                                            v-bind:value="accountTemplate.template_code"
                                          >
                                            {{ accountTemplate.template_name }}
                                          </option>
                                        </select>
                                 </div>
                            </div>

                            <div class="form-row">
                              <button class="btn btn-primary" @click="saveClientAccountTemplates()"> Save Template </button>
                              <table class="table">
                                  <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                      <th scope="col">Currency iso code</th>
                                      <th scope="col">Client Subtype code </th>
                                      <th scope="col">Account Template code</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                        <tr v-for="(value, index) in configuredAccountTemplates">
                                           <td>{{ index +1 }}</td>
                                           <td>{{ value.currency }}</td>
                                           <td>{{ value.client_sub_type }}</td>
                                           <td>{{ value.account_opening_template }}</td>
                                        </tr>
                                  </tbody>
                              </table>
                            </div>  
                  </div>

                   <div id="feeConfig" class="tab-pane  pd-20 pd-xl-25">
                        <h6 class="mg-t-10 mg-b-20">Fees</h6>
                              <div class="form-row">
                                  <div class="form-group col-md-4">  
                                      <div class="form-row">
                                        <label>Select Currency</label>
                                            <select
                                                class="custom-select"
                                                v-model="selectedFee.currency"
                                                @change="selectFeeEvent"
                                              >
                                                <option
                                                  v-for="curr in currencies"
                                                  v-bind:value="curr.currency_iso_code"
                                                >
                                                  {{ curr.name }} 
                                                </option>
                                            </select>
                                        </div>    
                                        <div class="form-row">
                                        <label>Select Entity Type</label>
                                        <select
                                            class="custom-select"
                                            v-model="selectedFee.entity_type"
                                          >
                                          <option
                                            v-for="clientsubtype in clientsubtypes"
                                            v-bind:value="clientsubtype.subtype_code"
                                          >
                                            {{ clientsubtype.subtype_name }}
                                          </option>
                                        </select>
                                      
                                      </div>  
                                  </div>

                                  <div class="form-group col-md-8">  
                                    <div class="form-row"> 
                                          <div class="form-group col-md-4">                                 
                                            <label>Insurance Purchase</label>
                                                <select
                                                    class="custom-select"
                                                    v-model="entityfee.insurance_purchase"
                                                    :disabled="feeInputsDisabled"
                                                  >
                                                    <option
                                                      v-for="fee in feeFilteredCurrency"
                                                      v-bind:value="fee.system_fee_ref"
                                                   
                                                    >
                                                      {{ fee.fee_name }}
                                                    </option>
                                                </select>
                                          </div>

                                          <div class="form-group col-md-4">                                 
                                            <label>Insurance Loan(ipf)</label>
                                                <select
                                                    class="custom-select"
                                                    v-model="entityfee.insurance_ipf"
                                                    :disabled="feeInputsDisabled"
                                                  >
                                                <option
                                                      v-for="fee in feeFilteredCurrency"
                                                      v-bind:value="fee.system_fee_ref"
                                                    >
                                                      {{ fee.fee_name }}
                                                    </option>
                                                </select>
                                          </div>


                                          <div class="form-group col-md-4">                                 
                                            <label>Insurance Renewal</label>
                                                <select
                                                    class="custom-select"
                                                    v-model="entityfee.insurance_renewal"
                                                    :disabled="feeInputsDisabled"
                                                  >
                                                  <option
                                                      v-for="fee in feeFilteredCurrency"
                                                      v-bind:value="fee.system_fee_ref"
                                                    >
                                                      {{ fee.fee_name }}
                                                    </option>
                                                </select>
                                          </div>
                                         
                                    </div>
                                    <div class="form-row"> 
                                          <div class="form-group col-md-6">                                 
                                            <label>School Fee Purchase</label>
                                                <select
                                                    class="custom-select"
                                                    v-model="entityfee.schoolfee_purchase"
                                                    :disabled="feeInputsDisabled"
                                                  >
                                                <option
                                                      v-for="fee in feeFilteredCurrency"
                                                      v-bind:value="fee.system_fee_ref"
                                                    >
                                                      {{ fee.fee_name }}
                                                    </option>
                                                </select>
                                          </div>
                                          <div class="form-group col-md-6">                                 
                                            <label>School Loan</label>
                                                <select
                                                    class="custom-select"
                                                    v-model="entityfee.schoolfee_loan"
                                                    :disabled="feeInputsDisabled"
                                                  >
                                                  <option
                                                        v-for="fee in feeFilteredCurrency"
                                                      v-bind:value="fee.system_fee_ref"
                                                    >
                                                      {{ fee.fee_name }}
                                                    </option>
                                                </select>
                                          </div>

                                          <div class="form-group col-md-6">                                 
                                            <label>Topup Fee</label>
                                                <select
                                                    class="custom-select"
                                                    v-model="entityfee.topup_fee"
                                                    :disabled="feeInputsDisabled"
                                                  >
                                                  <option
                                                        v-for="fee in feeFilteredCurrency"
                                                      v-bind:value="fee.system_fee_ref"
                                                    >
                                                      {{ fee.fee_name }}
                                                    </option>
                                                </select>
                                          </div>

                                          <div class="form-group col-md-6">                                 
                                            <label>Withdrawal Fee</label>
                                                <select
                                                    class="custom-select"
                                                    v-model="entityfee.withdrawal_fee"
                                                    :disabled="feeInputsDisabled"
                                                  >
                                                  <option
                                                        v-for="fee in feeFilteredCurrency"
                                                      v-bind:value="fee.system_fee_ref"
                                                    >
                                                      {{ fee.fee_name }}
                                                    </option>
                                                </select>
                                          </div>
                                          
                                    </div>
                                  </div>
                              </div> 

                              <div class="form-row">
                                  <div class="form-group col-md-4"> 
                                      <button class="btn btn-primary" @click="saveClientFees()"> Save Fees </button>
                                  </div> 
                              </div>
 
                              <div class="row" v-for="(value, index) in configuredFees" >
                                        <div class="col-sm card">
                                          <div class="card-header"> {{index + 1 }} . <b>Currency Iso Code :</b> {{value.currency}}  <b>Entity Type :</b> {{value.entity_type}}</div>

                                            <div class="card-body">
                                                <div class="row">
                                                   <div class="col-sm-2"><b>Insurance Purchase :</b> {{value.fees.insurance_purchase}}</div>
                                                    <div class="col-sm-2"><b>Insurance Ipf :</b> {{value.fees.insurance_ipf}}</div>
                                                    <div class="col-sm-2"><b>Insurance Renewal  :</b> {{value.fees.insurance_renewal}}</div>
                                                    <div class="col-sm-2"><b>School Purchase :</b> {{value.fees.schoolfee_purchase}}</div>
                                                    <div class="col-sm-2"><b>School Loan :</b> {{value.fees.schoolfee_loan}}</div>
                                                    <div class="col-sm-2"><b>Topup Fee :</b> {{value.fees.topup_fee}}</div>
                                                    <div class="col-sm-2"><b>Withdraw Fee :</b> {{value.fees.withdrawal_fee}}</div>
                                                    
                                                  </div>   
                                              </div>
                                         </div>
                              </div>       

                    </div>  

                   <div id="securityConfig" class="tab-pane  pd-20 pd-xl-25">
                        <h6 class="mg-t-10 mg-b-20">Security</h6>
                              <div class="form-row">
                                  <div class="form-group col-md-4"> 
                                            <label>Default Role </label>
                                                <select
                                                    class="custom-select"
                                                    v-model="security.default_role"
                                                  >
                                                  <option
                                                        v-for="role in roles"
                                                        v-bind:value="role.role_code"
                                                    >
                                                      {{ role.name }}
                                                    </option>
                                                </select>  
                                  </div> 

                                  <div class="form-group col-md-4">  
                                            <label>Default Security Context</label>
                                                <select
                                                    class="custom-select"
                                                    v-model="security.default_security_context"
                                                  >
                                                  <option
                                                        v-for="securityContext in securityContexts"
                                                      v-bind:value="securityContext.security_context_code"
                                                    >
                                                      {{ securityContext.name }}
                                                    </option>
                                                </select>

                                  </div> 

                                    <div class="form-group col-md-4"> 
                                        <label>Enable Global 2 Factor Login</label> 
                                      <div class="custom-control custom-radio">
                                        <input type="radio" id="secYes" v-model="security.global_twofactor_enabled" value="YES" class="custom-control-input" checked>
                                        <label class="custom-control-label" for="secYes">YES</label>
                                      </div>

                                      <div class="custom-control custom-radio">
                                        <input type="radio" id="secNO" v-model="security.global_twofactor_enabled" value="NO" class="custom-control-input">
                                        <label class="custom-control-label" for="secNO">NO</label>
                                      </div>
 
                                  </div> 
                          
                                     
                                        

                              </div>   
                                    <div class="row">
                                      <button class="btn btn-primary" @click="saveSecurity()"> Save Security Config </button>
                                    </div>
                                   <hr>
                                   <div class="row">
                                      <div class="col-sm"><p>Default Role :  {{security.default_role}}</p></div>
                                      <div class="col-sm"><p>Default Security Context : {{security.default_security_context}}</p></div>
                                      <div class="col-sm"><p>Two Factor Enabled  : {{security.global_twofactor_enabled}}</p></div>
                                    </div>
                                    
                   </div>

               </div>
           </div>      
    </div>
   </div>  
</template>

<script>  
export default { 
    data () {
         return {
            system_fees:[],
            feeFilteredCurrency:[],
            selectedFee:{
             currency:"",
             entity_type:""
            },
             entityfee:{
              insurance_purchase:"",
              insurance_ipf:"",
              insurance_renewal:"",
              schoolfee_purchase:"",
              schoolfee_loan:"",
              withdrawal_fee:"",
              topup_fee:""
            },
            entityDefault:{
              currency:""
            },

            currencies: [],
            accountTemplates:[],
            clientsubtypes:[],
            accounttemplates:{
              currency:"",
              client_sub_type:"",
              account_opening_template:""
            },

            feeInputsDisabled:true,
            configuredAccountTemplates:[],
            configuredFees:[],
            roles:[],
            securityContexts:[],
            security:{
              default_role:"",
              default_security_context:"",
              global_twofactor_enabled:""
            },
            currentconfig:{}
         }

      },

      methods: {
     
       async saveSecurity () {
              let updateData={}
              updateData.security=this.security;
            await this.updateClientConfig(updateData);

       },
      async saveDefaultEntityConfig () {
                  let updateData={}
                      updateData.default=this.entityDefault;
                  await this.updateEntityConfig(updateData);
          },
        async selectFeeEvent() {
            this.feeInputsDisabled=false;
            this.feeFilteredCurrency=this.system_fees.filter(fee => fee.entity_fee_currency === this.selectedFee.currency);
        },

         updateClientConfig(data) {
          return new Promise(async (resolve, reject) => {
             Event.$emit('loader:show', true)
                  try {
                    await req.callServerSecure({
                      resource: "client_config",
                      action: "create",
                      data: data,
                      meta_data: {},
                    });  
                        Event.$emit('loader:show', false)     
                    resolve(true);
                  } catch (err) {
                      Event.$emit('loader:show', false)  
                    reject(err);
                  }
             });
         },

          async  saveClientAccountTemplates() {

               if(this.configuredAccountTemplates.some(tconf => tconf.currency === this.accounttemplates.currency) && this.configuredAccountTemplates.some(tconf => tconf.client_sub_type === this.accounttemplates.client_sub_type)){
                  notification.notify('error',"Client Account Template Configuration", "Client subtype with the currency already exists")
               }
                else{

                  this.configuredAccountTemplates.push(this.accounttemplates);
                  let updateData={}
                      updateData.account_opening_template=this.configuredAccountTemplates;
                  await this.updateClientConfig(updateData);
                }

            },

           async saveClientFees() {
              if(this.selectedFee.entity_type === "") {
                     notification.notify('error',"Client Fee Configuration", "You need to select an Entity type");
                     return;
              }
               if(this.configuredFees.some(fconf => fconf.currency === this.selectedFee.currency && fconf.entity_type === this.selectedFee.entity_type)){
                  notification.notify('error',"Client Fee Configuration", "Client Currency fee already configured");
                  return;
               }else{

         

               let configuredEntityFee= {
                   currency:this.selectedFee.currency,
                   entity_type:this.selectedFee.entity_type,
                   fees:this.entityfee
                 }
                
                this.configuredFees.push(configuredEntityFee)
                          let updateData={}
                              updateData.fees=this.configuredFees;
                  await this.updateClientConfig(updateData);
               }
          
            },
        getClientConfig() {
                return new Promise(async (resolve, reject) => {
                  try {
                    let res = await req.callServerSecure({
                      resource: "client_config",
                      action: "get",
                      data: {},
                      meta_data: {},
                    });

                   if(res.data.result.account_opening_template) {
                     this.configuredAccountTemplates=res.data.result.account_opening_template
                   }
                   if(res.data.result.fees) {
                      this.configuredFees=res.data.result.fees;
                      console.log(" === Configured Fees ==== ", this.configuredFees)
                   }
                   if(res.data.result.security) {
                      this.security=res.data.result.security
                   }
                  if(res.data.result.default) {
                      this.entityDefault=res.data.result.default
                   }

                    resolve(true);
                  } catch (err) {
                    reject(err);
                  }
                });
              },
            fetchCurrencies() {
                return new Promise(async (resolve, reject) => {
                  try {
                    let res = await req.callServerSecure({
                      resource: "currency",
                      action: "list",
                      data: {},
                      meta_data: {},
                    });

                    this.currencies = res.data.result.currencies;
                    resolve(true);
                  } catch (err) {
                    reject(err);
                  }
                });
              },
          fetchAccountTemplate() {
            return new Promise(async (resolve, reject) => {
              try {
                let res = await req.callServerSecure({
                  resource: "entity_account_template",
                  action: "list",
                  data: {
                    entity:"CLIENT"
                  },
                  meta_data: {},
                });
                this.accountTemplates = res.data.result.templates;
                resolve(true);
              } catch (err) {
                reject(err);
              }
            });
          },
          fetchSytemFees() {
            return new Promise(async (resolve, reject) => {
              try {
                let res = await req.callServerSecure({
                  resource: "fees",
                  action: "list_fees",
                  data: {},
                  meta_data: {},
                });
                this.system_fees = res.data.result.entity_fees;
                resolve(true);
              } catch (err) {
                reject(err);
              }
            });
          },
          async listroles() {
            return new Promise(async (resolve, reject) => {
                  try {
                    let res = await req.callServerSecure({
                    resource: "role",
                    action: "list",
                    data: {},
                    meta_data: {},
                    });

                    this.roles = res.data.result.roles;
                    resolve(true);
                  } catch (err) {
                       reject(err);
                  }
              });  
           },
            fetchClientSubtypes () {
                  return new Promise(async (resolve, reject) => {
                  try {
                    let res = await req.callServerSecure({
                      resource: "client_sub_type",
                      action: "list",
                      data: {},
                      meta_data: {}
                    });

                    this.clientsubtypes = res.data.result.client_subtypes
                    resolve(true);
                  } catch (err) {
                    reject(err);
                  }
                });
            },
            async listSecurityContexts() {
                      return new Promise(async (resolve, reject) => {
                        try {
                        let res = await req.callServerSecure({
                          resource: "security_context",
                          action: "list",
                          data: {},
                          meta_data: {},
                        });
                        this.securityContexts = res.data.result.security_contexts;
                          resolve();
                        }
                        catch (err) {
                              reject(err);
                        }
            
                      });
                  },
          },
    async mounted(){
           await this.getClientConfig();
          await this.fetchSytemFees();
          await this.fetchCurrencies();
          await this.fetchAccountTemplate();
          await this.fetchClientSubtypes();
          await this.listroles();
          await this.listSecurityContexts()
        Event.$emit('loader:show', false)
     }  
  };
</script>

<style lang="less">
.orange {
}
</style>
